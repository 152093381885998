import { createRouter, createWebHistory } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";

const routes = [
  // PUBLIC ROUTES
  {
    path: "/",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/public/HomeView.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/public/AboutView.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/public/Contact.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("../views/public/Privacy.vue"),
  },
  {
    path: "/blog/:slug",
    name: "BlogPost",
    component: () => import("../views/public/BlogPost.vue"),
    props: true,
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import("../views/public/Blog.vue"),
  },
  {
    path: "/plants/lookup",
    name: "PlantLookup",
    component: () => import("../views/public/PlantLookup.vue"),
  },
  {
    path: "/plants/selector",
    name: "PlantSelector",
    component: () => import("../views/public/PlantSelector.vue"),
  },
  {
    path: "/plants/:slug",
    name: "Plant",
    component: () => import("../views/public/PlantView.vue"),
    props: true,
  },
  {
    path: "/plants",
    name: "Plants",
    component: () => import("../views/public/PlantsView.vue"),
    props: true,
  },
  {
    path: "/shipping",
    name: "Shipping",
    component: () => import("../views/public/ShippingView.vue"),
  },
  {
    path: "/category/:slug",
    name: "PlantCategory",
    component: () => import("../views/public/PlantCategory.vue"),
  },
  {
    path: "/favorites",
    name: "Favorites",
    component: () => import("../views/public/FavoritesList.vue"),
  },
  // ADMIN ROUTES
  {
    path: "/admin",
    name: "Admin",
    component: () => import("../views/admin/Admin.vue"),
    beforeEnter: authGuard,
    children: [
      {
        path: "",
        name: "AdminHome",
        component: () => import("../views/admin/AdminView.vue"),
      },
      {
        path: "plants",
        name: "PlantsAdmin",
        component: () => import("../views/admin/AdminPlantsView.vue"),
      },
      {
        path: "containers",
        name: "ContainersAdmin",
        component: () => import("../views/admin/AdminContainersView.vue"),
      },
      {
        path: "blog-posts",
        name: "BlogPostsAdmin",
        component: () => import("../views/admin/AdminBlogPostsView.vue"),
      },
      {
        path: "plants/create",
        name: "PlantAdminCreate",
        component: () => import("../views/admin/AdminPlantCreateView.vue"),
      },
      {
        path: "blog-posts/:slug",
        name: "BlogPostAdmin",
        props: true,
        component: () => import("../views/admin/AdminBlogPostView.vue"),
      },
      {
        path: "blog-posts/:slug/edit",
        name: "BlogPostAdminEdit",
        props: true,
        component: () => import("../views/admin/AdminBlogPostEditView.vue"),
      },
      {
        path: "blog-posts/create",
        name: "BlogPostAdminCreate",
        props: true,
        component: () => import("../views/admin/AdminBlogPostCreateView.vue"),
      },
      {
        path: "containers/:id",
        name: "ContainerAdmin",
        props: true,
        component: () => import("../views/admin/AdminContainerView.vue"),
      },
      {
        path: "containers/:id/edit",
        name: "ContainerAdminEdit",
        props: true,
        component: () => import("../views/admin/AdminContainerEditView.vue"),
      },
      {
        path: "containers/create",
        name: "ContainerAdminCreate",
        props: true,
        component: () => import("../views/admin/AdminContainerCreateView.vue"),
      },
      {
        path: "plants/:slug",
        name: "PlantAdmin",
        component: () => import("../views/admin/AdminPlantView.vue"),
      },
      {
        path: "plants/:slug/edit",
        name: "PlantAdminEdit",
        props: true,
        component: () => import("../views/admin/AdminPlantEditView.vue"),
      },
      {
        path: "plants/:slug/delete",
        name: "PlantAdminDelete",
        props: true,
        component: () => import("../views/admin/AdminPlantDeleteView.vue"),
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/public/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
