import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createAuth0 } from "@auth0/auth0-vue";
import { createPinia } from "pinia";
import { createHead } from "@unhead/vue";

const pinia = createPinia();
const head = createHead();

createApp(App)
  .use(pinia)
  .use(router)
  .use(head)
  .use(
    createAuth0({
      domain: "dev-vnccgc7w0lurj62x.us.auth0.com",
      clientId: "VGR27gZJPE7u7Al8HxduSZBpaZl11onx",
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
    })
  )
  .mount("#app");
