<template>
  <div class="footer">
    <div class="logo">
      <router-link :to="'/'"
        ><img
          src="https://s3.us-east-2.amazonaws.com/photos.hortech.com/web/ht-logo-new-horizontal-white-header.png"
          alt="Hortech logo"
          loading="lazy"
      /></router-link>
    </div>
    <img
      loading="lazy"
      class="maeap"
      alt="MAEAP logo - environmentally assured farm"
      src="https://s3.us-east-2.amazonaws.com/photos.hortech.com/web/pages/maeap.jpg"
    />
    <strong
      ><a class="phone-num" href="tel:8008751392">(800) 875-1392</a></strong
    >
    <ul class="footer-links">
      <li class="footer-link">
        <a href="https://liveroof.com/careers/" target="'_blank'">Careers</a>
      </li>
      <li class="footer-link">
        <router-link :to="'/contact'">Contact</router-link>
      </li>
      <li class="footer-link">
        <router-link :to="'/privacy'">Privacy</router-link>
      </li>
      <li class="footer-link">
        <a href="https://s3.us-east-2.amazonaws.com/photos.hortech.com/Hortech+Catalog_2024.pdf" target="'_blank'">2024 Catalog</a>
      </li>
    </ul>
    <div class="social-icons-container">
      <p>Follow Us On Social Media:</p>
      <div class="social-icons">
        <a
          href="https://facebook.com/grownearthfriendly"
          target="_blank"
          aria-label="Hortech Facebook page"
          ><i class="social-icon fa fa-facebook"></i
        ></a>
        <a
          href="https://instagram.com/grownearthfriendly"
          target="_blank"
          aria-label="Hortech Instagram page"
          ><i class="social-icon fa fa-instagram"></i
        ></a>
        <a
          href="https://youtube.com/@Grown_Earth_Friendly"
          target="_blank"
          aria-label="Hortech YouTube channel"
          ><i class="social-icon fa fa-youtube"></i
        ></a>
      </div>
    </div>
    <div class="copyright">&copy; {{ copyrightYear }} Hortech, Inc</div>
  </div>
</template>

<script setup>
import { computed } from "vue";

/*********/
/* STATE */
/*********/
const copyrightYear = computed(() => {
  return new Date().getFullYear();
});

/***********/
/* METHODS */
/***********/

/*******************/
/* LIFECYCLE HOOKS */
/*******************/
</script>

<style scoped>
.footer-links {
  display: flex;
  width: 30%;
  max-width: 500px;
  justify-content: space-evenly;
}

.footer-link {
  text-decoration: underline;
}

.social-icons-container {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
}

.social-icons-container > p {
  font-weight: bold;
}

.social-icons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  column-gap: 1rem;
}

.phone-num {
  font-size: 1.5rem;
}

.social-icon {
  font-size: 2rem;
}

.fa-facebook {
  color: #3b5998;
}

.fa-youtube {
  color: #bb0000;
}

.fa-instagram {
  color: #517fa4;
}

.logo {
  height: 6rem;
}

.logo img {
  max-height: 100%;
}

/* * * * * * * * */
/* MEDIA QUERIES */
/* * * * * * * * */
@media screen and (min-width: 1025px) {
  .footer {
    display: grid;
    grid-template-rows: 2fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 2fr 2fr;
    justify-items: center;
    padding: 1rem;
    align-items: center;
    justify-content: space-evenly;
    border-top: 20px solid rgb(0, 67, 143);
  }

  .logo {
    padding: none;
  }

  .social-icons-container,
  .footer-links {
    width: 100%;
  }

  .copyright {
    grid-row: 2;
    grid-column: 1 / 6;
  }

  .maeap {
    width: 50%;
  }

  .phone-num {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    row-gap: 2rem;
    border-top: 20px solid rgb(0, 67, 143);
    padding-bottom: 1rem;
  }

  .logo {
    padding: 10px;
  }

  .maeap {
    width: 150px;
  }

  .footer-links {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
}

@media print {
  .footer {
    display: none;
  }
}
</style>
