<template>
  <nav class="main-nav" ref="mainNav">
    <div class="logo-container">
      <router-link :to="'/'"
        ><img
          class="logo"
          :src="[
            state.windowWidth > 768
              ? 'https://s3.us-east-2.amazonaws.com/photos.hortech.com/web/ht-logo-new-horizontal-transparent-header.png'
              : 'https://s3.us-east-2.amazonaws.com/photos.hortech.com/web/ht-logo-new-stacked-transparent.png',
          ]"
          alt="Hortech logo"
      /></router-link>
    </div>
    <div class="nav">
      <!-- Top Level Items -->
      <ul class="nav-items">
        <li
          v-for="navItem in state.navItems"
          :key="navItem._id"
          class="nav-item"
          @mouseover="navItem.showSubItems = true"
          @mouseleave="navItem.showSubItems = false"
        >
          <router-link :to="'/' + navItem.path">
            {{ navItem.text }}
            <i v-if="navItem.subItems" class="fa-solid fa-chevron-down"></i>
          </router-link>
          <!-- Sub Items -->
          <ul
            v-if="navItem.subItems"
            class="sub-nav-items"
            :class="{
              dropped: navItem.showSubItems,
              undropped: !navItem.showSubItems,
            }"
          >
            <li
              v-for="subItem in navItem.subItems"
              :key="subItem._id"
              class="nav-item"
              @mouseover.prevent="subItem.showSubItems = true"
              @mouseleave="subItem.showSubItems = false"
            >
              <router-link
                :to="subItem.path"
                v-if="subItem.subItems && subItem.path != ''"
              >
                {{ subItem.text }}
                <i v-if="subItem.subItems" class="fa-solid fa-chevron-down"></i>
              </router-link>
              <router-link v-else-if="subItem.path != ''" :to="subItem.path">{{
                subItem.text
              }}</router-link>
              <div v-else class="unclickable-link">
                {{ subItem.text }}
                <i v-if="subItem.subItems" class="fa-solid fa-chevron-down"></i>
              </div>
              <!-- Sub Sub Items (Categories) -->
              <ul
                v-if="subItem.subItems"
                class="sub-sub-nav-items"
                :class="{
                  dropped: subItem.showSubItems,
                  undropped: !subItem.showSubItems,
                }"
              >
                <li
                  v-for="subSubItem in subItem.subItems"
                  :key="subSubItem._id"
                  class="nav-item"
                >
                  <router-link :to="subSubItem.path">{{
                    subSubItem.text
                  }}</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li v-if="userStore.favoritePlants.length" class="nav-item">
          <router-link :to="'/favorites'"
            >Favorites ({{ userStore.favoriteCount }})</router-link
          >
        </li>
        <li class="nav-item" @click="toggleSearch">
          <i class="fa-solid fa-magnifying-glass"></i>
        </li>
      </ul>
    </div>
    <div
      class="mobile-menu-container round-bottom"
      @click="toggleMobileMenu"
      ref="mobileMenuContainer"
    >
      <p class="mobile-menu-btn"><span class="no-close">≡</span> Menu</p>
    </div>
    <div class="mobile-menu" ref="mobileMenu">
      <!-- Top Level Items -->
      <ul class="mobile-nav-items">
        <li
          v-for="navItem in state.navItems"
          :key="navItem._id"
          class="mobile-nav-item"
        >
          <div class="no-close">
            <router-link
              :to="'/' + navItem.path"
              @click="toggleMobileMenu"
              class="no-close"
            >
              {{ navItem.text }}
            </router-link>
            <i
              v-if="navItem.subItems"
              class="fa-solid fa-chevron-down no-close"
              @click.prevent="navItem.showSubItems = !navItem.showSubItems"
            ></i>
          </div>
          <!-- Sub Items -->
          <ul
            v-if="navItem.subItems"
            :class="{
              dropped: navItem.showSubItems,
              undropped: !navItem.showSubItems,
            }"
          >
            <li v-for="subItem in navItem.subItems" :key="subItem._id">
              <div
                v-if="subItem.subItems && subItem.path != ''"
                @click="subItem.showSubItems = !subItem.showSubItems"
                class="no-close"
              >
                <router-link :to="subItem.path" class="no-close">{{
                  subItem.text
                }}</router-link>
                <i
                  v-if="subItem.subItems"
                  class="fa-solid fa-chevron-down no-close"
                ></i>
              </div>
              <router-link
                v-else-if="subItem.path != ''"
                :to="subItem.path"
                @click="toggleMobileMenu"
                class="no-close"
                >{{ subItem.text }}</router-link
              >
              <div
                v-else
                class="unclickable-link no-close"
                @click="subItem.showSubItems = !subItem.showSubItems"
              >
                {{ subItem.text }}
                <i
                  v-if="subItem.subItems"
                  class="fa-solid fa-chevron-down no-close"
                ></i>
              </div>
              <!-- Sub Sub Items (Categories) -->
              <ul
                v-if="subItem.subItems"
                :class="{
                  dropped: subItem.showSubItems,
                  undropped: !subItem.showSubItems,
                }"
                class="no-close"
              >
                <li
                  v-for="subSubItem in subItem.subItems"
                  :key="subSubItem._id"
                  class="no-close"
                >
                  <router-link
                    :to="subSubItem.path"
                    @click="toggleMobileMenu"
                    class="no-close"
                    >{{ subSubItem.text }}</router-link
                  >
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li v-if="userStore.favoritePlants.length" class="mobile-nav-item">
          <div>
            <router-link @click="toggleMobileMenu" :to="'/favorites'"
              >Favorites ({{ userStore.favoriteCount }})</router-link
            >
          </div>
        </li>
        <li class="mobile-nav-item no-close">
          <div>
            <input
              type="search"
              class="search-bar no-close"
              placeholder="Search for a plant"
              @keypress.enter="search(), toggleMobileMenu()"
              v-model="state.searchTerm"
            />
          </div>
        </li>
      </ul>
    </div>
    <div class="search-container" style="display: none">
      <input
        type="search"
        class="search-bar"
        placeholder="What are you searching for?"
        @keypress.enter="search()"
        v-model="state.searchTerm"
      />
      <button type="submit" @click="search()" class="btn btn-green">
        Search
      </button>
    </div>
  </nav>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useUserStore } from "../../stores/userStore";

/*********/
/* STATE */
/*********/
const mobileMenu = ref(null);

const mobileMenuContainer = ref(null);

const router = useRouter();

const state = reactive({
  navItems: [
    {
      text: "Our Plants",
      path: "plants",
      subItems: [
        {
          text: "View All",
          path: "/plants",
        },
        {
          text: "Categories",
          path: "",
          subItems: [
            {
              text: "Sexy Succulents",
              path: "/category/succulents",
            },
            {
              text: "Climbing Vines",
              path: "/category/vines",
            },
            {
              text: "Ferns",
              path: "/category/ferns",
            },
            {
              text: "Perennials & Ground Covers",
              path: "/category/perennials",
            },
            {
              text: "Ornamental Grasses",
              path: "/category/grasses",
            },
            {
              text: "Hortech Originals",
              path: "/plants/?other=Hortech+Original",
            },
          ],
          showSubItems: false,
        },
        {
          text: "Plant Selector",
          path: "/plants/selector",
        },
        {
          text: "Plant Lookup",
          path: "/plants/lookup",
        },
      ],
      showSubItems: false,
    },
    {
      text: "Ordering/Delivery",
      path: "shipping",
    },
    {
      text: "Blog",
      path: "blog",
    },
    {
      text: "About",
      path: "about",
    },
    {
      text: "Contact",
      path: "contact",
    },
  ],
  showMobileMenu: false,
  windowWidth: window.innerWidth,
  searchTerm: "",
});

const userStore = useUserStore();

/***********/
/* METHODS */
/***********/
function toggleMobileMenu() {
  if (state.showMobileMenu) {
    state.showMobileMenu = false;
    mobileMenu.value.classList.remove("show");
    mobileMenuContainer.value.classList.add("round-bottom");
  } else {
    state.showMobileMenu = true;
    mobileMenu.value.classList.add("show");
    mobileMenuContainer.value.classList.remove("round-bottom");
  }

  // Close all "dropped" menus
  let droppedItems = document.querySelectorAll(".dropped");
  if (droppedItems) {
    droppedItems.forEach((li) => {
      li.classList.replace("dropped", "undropped");
    });
  }
}

function toggleMobileOutside(e) {
  if (
    state.showMobileMenu &&
    !e.target.classList.contains("mobile-menu-container") &&
    !e.target.classList.contains("mobile-menu-btn") &&
    !e.target.classList.contains("mobile-menu") &&
    !e.target.classList.contains("mobile-nav-items") &&
    !e.target.classList.contains("mobile-nav-item") &&
    !e.target.classList.contains("no-close")
  ) {
    state.showMobileMenu = false;
    mobileMenu.value.classList.remove("show");
    mobileMenuContainer.value.classList.add("round-bottom");

    // Close all "dropped" menus
    let droppedItems = document.querySelectorAll(".dropped");
    if (droppedItems) {
      droppedItems.forEach((li) => {
        li.classList.replace("dropped", "undropped");
      });
    }
  }
}

function toggleSearch() {
  let searchContainer = document.querySelector(".search-container");

  if (
    searchContainer.style.display == "" ||
    searchContainer.style.display == "none"
  ) {
    searchContainer.style.display = "flex";
    searchContainer.focus();
  } else {
    searchContainer.style.display = "none";
  }
}

function search() {
  router.push({ name: "Plants", query: { keyword: state.searchTerm } });
  state.searchTerm = "";

  if (!state.showMobileMenu) {
    toggleSearch();
  }
}

/*******************/
/* LIFECYCLE HOOKS */
/*******************/
onMounted(() => {
  // Allow mobile menu to be closed by tapping outside of it
  // NOTE: the mobile menu items get this listener but are excluded
  // from closing in the toggleMobileOutside() function
  document.addEventListener("click", toggleMobileOutside);
});
</script>

<style scoped>
.unclickable-link {
  cursor: pointer;
}

.unclickable-link:hover {
  color: #ddd;
}

.search-container {
  grid-column: 2;
  grid-row: 3;
  border: 5px solid rgb(113, 169, 79);
  position: relative;
  width: 75%;
  justify-self: right;
  align-self: center;
  z-index: 9999;
  height: 50px;
  top: 1.5rem;
  display: flex;
  background: white;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
}

.search-bar {
  font-size: 1rem;
  height: 100%;
  width: 80%;
}

.search-bar:focus {
  outline: none;
}

.search-container button {
  height: 25px;
}

.main-nav {
  display: grid;
  height: 130px;
  margin: 10px 0;
  grid-template-rows: 2fr 2fr 1fr;
  grid-template-columns: 1fr 3fr;
}

.logo-container {
  display: flex;
  background-color: white;
  justify-content: center;
  position: relative;
  grid-row: 1 / 4;
  grid-column: 1;
}

.logo-container > a {
  height: fit-content;
  text-align: center;
  align-self: center;
}

.header .logo {
  max-height: 150px;
  max-width: 100%;
  padding: 1rem;
}

.nav {
  height: 60%;
  position: relative;
  grid-column: 2;
  grid-row: 1 / 4;
  width: 100%;
  background: rgb(113, 169, 79);
  align-self: center;
  justify-self: right;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.mobile-menu-container {
  font-size: 3rem;
  display: none;
  color: white;
  background: rgb(113, 169, 79);
  width: 30%;
  align-self: center;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  position: absolute;
  right: 0;
  z-index: 3;
  box-shadow: -1px 2px 5px 0px #00000055;
  height: 50px;
  top: 65px;
  font-size: 1rem;
}

.mobile-menu {
  position: absolute;
  right: 0;
  top: 115px;
  background-color: rgb(113, 169, 79);
  z-index: 3;
  box-shadow: 0px 7px 5px 0px #00000066;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  display: none;
  width: 90%;
}

.mobile-menu .dropped li {
  padding-left: 1rem;
  background: rgb(129 186 94);
  font-size: 0.85rem;
}

.mobile-menu a {
  color: white;
}

.nav-items {
  display: flex;
  position: relative;
  height: 100%;
  text-align: right;
  align-items: center;
  justify-content: space-evenly;
}

.round-bottom {
  border-bottom-left-radius: 10px;
}

.show {
  display: initial;
}

.nav-item {
  text-align: left;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  justify-content: center;
  background-color: rgb(114 169 88);
  display: flex;
  font-family: "Rockwell";
}

.nav-item > a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
  color: white;
  column-gap: 0.5rem;
  background: rgba(0, 0, 0, 0);
  font-weight: bold;
}

.nav-item > a > i {
  padding-top: 0.25rem;
}

.nav-item a:hover {
  color: #ddd;
}

.fa-magnifying-glass {
  color: white;
  transform: rotateZ(90deg);
  cursor: pointer;
}

.sub-nav-items {
  position: absolute;
  top: 95%;
  text-align: left;
  z-index: 10;
  width: max-content;
}

.sub-sub-nav-items {
  position: absolute;
  top: 0;
  left: 100%;
  text-align: left;
  z-index: 10;
  width: max-content;
}

.sub-nav-items .nav-item {
  min-width: 200px;
  font-size: 1rem;
}

.nav-item:last-of-type {
  border-bottom-left-radius: 10px;
}

/* MOBILE */
.mobile-menu-btn {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.mobile-menu-btn span {
  font-size: 1.2rem;
}

.mobile-nav-item {
  width: 100%;
  font-family: "Rockwell";
}

.mobile-nav-item > div,
.nav-item > div,
.mobile-nav-item a,
.nav-item a,
.mobile-nav-item > div > i,
.mobile-nav-item .unclickable-link {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 1rem;
  color: white;
  column-gap: 0.5rem;
  background: rgba(0, 0, 0, 0);
  font-weight: bold;
}

.mobile-nav-item > ul div,
.nav-item > ul div {
  height: 100%;
  display: flex;
  align-items: center;
  color: white;
  column-gap: 0.5rem;
  background: rgba(0, 0, 0, 0);
  font-weight: bold;
}

.undropped {
  display: none;
}

.dropped {
  display: flex;
  flex-direction: column;
}

/** MEDIA QUERIES */
@media screen and (max-width: 900px) {
  .main-nav {
    grid-template-columns: 2fr 1fr;
  }

  .nav {
    display: none;
  }

  .mobile-menu-container {
    display: flex;
  }

  .nav-items {
    display: none;
  }

  .search-bar {
    width: 100%;
    margin-left: 1rem;
    padding-left: 0.5rem;
  }
}
</style>
