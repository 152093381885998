import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";

export const useUserStore = defineStore("user", {
  state: () => ({
    favoritePlants: useStorage("favoritePlants", []),
  }),
  actions: {
    addFavorite(plant) {
      if (this.isFavorited(plant)) {
        alert(plant.botanicalName + " is already in your favorites.");
      } else {
        this.favoritePlants.push(plant);
      }
    },
    removeFavorite(plant) {
      let plantIdx = this.favoritePlants.findIndex(
        (favoritePlant) => favoritePlant._id == plant._id
      );

      if (
        window.confirm(
          "Are you sure you want to remove " +
            plant.botanicalName +
            " from your favorites?"
        )
      ) {
        this.favoritePlants.splice(plantIdx, 1);
      }
    },
    resetFavorites() {
      if (
        window.confirm("Are you sure you want to reset your list of favorites?")
      ) {
        this.favoritePlants = [];
      }
    },
  },
  getters: {
    getFavorites: (state) => {
      return state.favoritePlants;
    },
    favoriteCount: (state) => {
      return state.favoritePlants.length;
    },
    isFavorited: (state) => {
      return (plant) =>
        state.favoritePlants.find(
          (favoritePlant) => favoritePlant._id === plant._id
        );
    },
  },
});
